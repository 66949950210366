import { createGlobalState, useAsyncState } from '@vueuse/core';
import {
  computed, onMounted, onUnmounted, reactive, ref, watch
} from 'vue';
import CompanyService from '@/services/CompanyService';
import SubscriptionService from '@/services/SubscriptionService';
import { CompanyGuestListResponse, InviteCompanyGuestRequest } from '@/models/Company';
import { useStore } from 'vue2-helpers/vuex';

export interface NewProjectGuest {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
  accepted: boolean;
}

const useProjectGuestState = createGlobalState((initialCompanyId: string | null = null) => {
  const store = useStore();
  const companyId = ref<string | null>(initialCompanyId);
  const { state: companyGuestList } = useAsyncState<CompanyGuestListResponse[]>(async () => CompanyService.getCompanyGuestList(companyId.value ?? ''), []);
  const companyGuestListRegistered = computed(() => companyGuestList.value.filter((guest) => guest.memberSince));

  const loadCompanyGuestList = async () => {
    if (companyId.value) {
      companyGuestList.value = await CompanyService.getCompanyGuestList(companyId.value);
    }
  };

  const projectList = ref<string[]>([]);
  const projectGuestList = computed(() => companyGuestList.value
    .filter((guest) => guest.projects?.filter((project) => projectList.value.includes(project.id)).length));

  const newProjectGuest = reactive<NewProjectGuest>({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    accepted: false
  });

  const reset = () => {
    newProjectGuest.firstname = '';
    newProjectGuest.lastname = '';
    newProjectGuest.email = '';
    newProjectGuest.message = '';
    newProjectGuest.accepted = false;
  };

  const canInviteNewGuest = computed(() => newProjectGuest.firstname !== ''
      && newProjectGuest.lastname !== ''
      && newProjectGuest.email !== ''
      && newProjectGuest.accepted);

  const inviteNewGuest = async () => {
    console.log('inviteNewGuest', canInviteNewGuest.value, companyId.value, projectList.value);
    if (canInviteNewGuest.value && companyId.value
      && projectList.value.length > 0
      && companyId.value !== '') {
      const inviteCompanyGuestRequest: InviteCompanyGuestRequest = {
        firstName: newProjectGuest.firstname,
        lastName: newProjectGuest.lastname,
        email: newProjectGuest.email,
        message: newProjectGuest.message,
        projectList: projectList.value
      };
      await CompanyService.inviteCompanyGuest(companyId.value, inviteCompanyGuestRequest);
      reset();
    }
  };

  const addGuestToProject = async (guestId: string, projectId: string | undefined = undefined) => {
    if (!projectId && projectList.value.length > 0) {
      projectList.value.forEach(async (projectId) => {
        await SubscriptionService.subscribeGuest(guestId, projectId, 'PROJECT');
        loadCompanyGuestList();
      });
    } else if (projectId) {
      SubscriptionService.subscribeGuest(guestId, projectId, 'PROJECT');
    }
  };

  const removeGuestFromProject = async (guestId: string, projectId: string | undefined = undefined, reload = true) => {
    if (!projectId && projectList.value.length > 0) {
      projectList.value.forEach(async (projectId) => {
        await SubscriptionService.unsubscribeGuestFromProject(guestId, projectId);
        if (reload) loadCompanyGuestList();
      });
    } else if (projectId) {
      SubscriptionService.unsubscribeGuestFromProject(guestId, projectId);
      if (reload) loadCompanyGuestList();
    }
  };

  const removeCompanyGuest = async (inviteId: string) => {
    if (companyId.value) {
      await CompanyService.removeCompanyGuest(companyId.value, inviteId);
      const guest = companyGuestList.value.find((guest) => guest.inviteId === inviteId);
      if (guest) {
        guest.projects?.forEach(async (project) => {
          await removeGuestFromProject(guest.id, project.id, false);
        });
      }
      loadCompanyGuestList();
    }
  };

  const reactivateCompanyGuest = async (inviteId: string) => {
    if (companyId.value) {
      await CompanyService.reactivateCompanyGuest(companyId.value, inviteId);
      loadCompanyGuestList();
    }
  };

  watch(() => companyId.value, (newVal) => {
    if (newVal) {
      loadCompanyGuestList();
    }
  });

  onMounted(() => {
    if (projectList.value.length === 0 && store.state.projectForum?.project?.id) {
      projectList.value = [store.state.projectForum.project.id];
    }
  });

  onUnmounted(() => {
    companyId.value = null;
    companyGuestList.value = [];
    projectList.value = [];
  });

  return {
    companyId,
    companyGuestList,
    companyGuestListRegistered,
    projectList,
    projectGuestList,
    newProjectGuest,
    canInviteNewGuest,
    inviteNewGuest,
    removeCompanyGuest,
    reactivateCompanyGuest,
    addGuestToProject,
    removeGuestFromProject,
    reset,

  };
});

export default useProjectGuestState;
