import {
  asyncComputed, createInjectionState, useAsyncState, useDebounce
} from '@vueuse/core';
import UserSearchService from '@/services/UserSearchService';
import { User } from '@/models/User';
import RecommendationService from '@/services/RecommendationService';
import {
  computed, reactive, ref, watchEffect
} from 'vue';
import ProjectForumService from '@/services/ProjectForumService';
import { ProjectListProject } from '@/views/project-forum/models/ProjectForum';
import { RecommendationInitiatedBy } from '@/models/Recommendations';
import useUser from '@/mixins/useUser';
import useProjectGuestState from '@/state/projectGuestState';

const [useProvideProjectGuests, injectedState] = createInjectionState(() => {
  const getUsers = async (searchTerm: string) => {
    const { entries } = await UserSearchService.searchUsers({
      simple: {
        term: searchTerm,
        fields: []
      }
    });
    return reactive(entries);
  };

  const { companyId } = useUser('self');
  const { companyId: stateCompanyId, companyGuestListRegistered: guests } = useProjectGuestState(companyId.value);

  watchEffect(() => {
    if (companyId.value) {
      stateCompanyId.value = companyId.value;
    }
  });

  const { state: employees } = useAsyncState<User[]>(async () => getUsers('Jakob'), []);

  const myProjects = asyncComputed(async () => {
    const myProjects = await RecommendationService.getMyProjects();
    return myProjects?.externalUserProjectList?.filter((project) => project.initiatedBy === RecommendationInitiatedBy.Creation) ?? [];
  }, []);

  const projectName = ref('');
  const projectNameDebounced = useDebounce(projectName, 250);

  const projectList = computed(() => myProjects.value.filter((project) => {
    if (project.project.status !== 'ACTIVE') return false;
    return project.project.title.toLocaleLowerCase().includes(
      projectNameDebounced.value.toLocaleLowerCase()
    );
  }).map((project) => project.project) ?? []);

  // @todo: remove later! Fake projects for employees
  const fakeProjectList = asyncComputed<ProjectListProject[]>(
    async () => ProjectForumService.getProjectList(),
    []
  );

  return {
    guests, employees, projectList, projectName, fakeProjectList
  };
});

export { useProvideProjectGuests };

export function useProjectGuests() {
  const injected = injectedState();
  if (injected == null) throw new Error('Please call `useProvideGuestsEmployees` on the appropriate parent component');
  return injected;
}
